<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 0C5.82187 0 5.0625 0.759375 5.0625 1.6875V25.3125C5.0625 26.2406 5.82187 27 6.75 27H23.625C24.5531 27 25.3125 26.2406 25.3125 25.3125V6.75L18.5625 0H6.75Z" fill="#E2E5E7"/>
    <path d="M20.25 6.75H25.3125L18.5625 0V5.0625C18.5625 5.99062 19.3219 6.75 20.25 6.75Z" fill="#B0B7BD"/>
    <path d="M25.3125 11.8125L20.25 6.75H25.3125V11.8125Z" fill="#CAD1D8"/>
    <path d="M21.9375 21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812H2.53125C2.06719 22.7812 1.6875 22.4016 1.6875 21.9375V13.5C1.6875 13.0359 2.06719 12.6562 2.53125 12.6562H21.0938C21.5578 12.6562 21.9375 13.0359 21.9375 13.5V21.9375Z" fill="#F15642"/>
    <path d="M5.36536 15.9865C5.36536 15.7638 5.54086 15.5208 5.82351 15.5208H7.38192C8.25942 15.5208 9.04917 16.108 9.04917 17.2336C9.04917 18.3001 8.25942 18.8941 7.38192 18.8941H6.25551V19.7851C6.25551 20.0821 6.06651 20.25 5.82351 20.25C5.60076 20.25 5.36536 20.0821 5.36536 19.7851V15.9865ZM6.25551 16.3704V18.0512H7.38192C7.83417 18.0512 8.19192 17.6521 8.19192 17.2336C8.19192 16.7619 7.83417 16.3704 7.38192 16.3704H6.25551Z" fill="white"/>
    <path d="M10.3704 20.2498C10.1477 20.2498 9.90466 20.1283 9.90466 19.8321V15.9998C9.90466 15.7576 10.1477 15.5813 10.3704 15.5813H11.9153C14.9984 15.5813 14.9309 20.2498 11.9761 20.2498H10.3704ZM10.7957 16.4048V19.4271H11.9153C13.737 19.4271 13.818 16.4048 11.9153 16.4048H10.7957Z" fill="white"/>
    <path d="M16.0245 16.4592V17.5316H17.7449C17.9879 17.5316 18.2309 17.7746 18.2309 18.01C18.2309 18.2327 17.9879 18.415 17.7449 18.415H16.0245V19.8316C16.0245 20.0679 15.8566 20.2493 15.6204 20.2493C15.3234 20.2493 15.142 20.0679 15.142 19.8316V15.9993C15.142 15.7572 15.3242 15.5808 15.6204 15.5808H17.9888C18.2858 15.5808 18.4613 15.7572 18.4613 15.9993C18.4613 16.2153 18.2858 16.4583 17.9888 16.4583H16.0245V16.4592Z" fill="white"/>
    <path d="M21.0938 22.7812H5.0625V23.625H21.0938C21.5578 23.625 21.9375 23.2453 21.9375 22.7812V21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812Z" fill="#CAD1D8"/>
  </svg>
</template>